import clsx from 'clsx'
import { formatPrice } from '~common/utils/format/priceFormat'
import { PriceType } from './types'

const pluralize = (num: number, word: string, plural = word + 's') =>
  [1, -1].includes(Number(num)) ? word : plural

export function Price({
  priceWithTax,
  currencyCode,
  interval,
  intervalCount,
  className = 'font-medium',
}: PriceType) {
  if (priceWithTax === null || priceWithTax === undefined || !currencyCode) {
    return null
  }
  if (
    typeof priceWithTax === 'number' &&
    typeof interval === 'string' &&
    intervalCount
  ) {
    if (intervalCount > 1) {
      return (
        <span className={clsx('whitespace-no-wrap', className)}>
          <span className={clsx('inline-block')}>
            {formatPrice(priceWithTax, currencyCode)}
          </span>
          {interval === 'future' ? null : (
            <span className="text-success-500">
              /{intervalCount} {pluralize(intervalCount, interval)}
            </span>
          )}
        </span>
      )
    }
    return (
      <span className={clsx('whitespace-no-wrap', className)}>
        <span className={clsx('inline-block')}>
          {formatPrice(priceWithTax, currencyCode)}
        </span>
        {interval === 'future' ? null : (
          <span className="text-success-500">
            /{pluralize(intervalCount, interval)}
          </span>
        )}
      </span>
    )
  }
  if (typeof priceWithTax === 'number') {
    return (
      <span className={clsx('inline-block', className)}>
        {formatPrice(priceWithTax, currencyCode)}
      </span>
    )
  }
  if (typeof priceWithTax === 'object') {
    if ('value' in priceWithTax) {
      return (
        <span className={clsx('inline-block', className)}>
          {formatPrice(priceWithTax.value, currencyCode)}
        </span>
      )
    }
    if (priceWithTax.min === priceWithTax.max) {
      return (
        <span className={clsx('inline-block', className)}>
          {formatPrice(priceWithTax.min, currencyCode)}
        </span>
      )
    }
  }
  return (
    <span className={clsx('inline-block', className)}>
      {formatPrice(priceWithTax?.min!, currencyCode)} -{' '}
      {formatPrice(priceWithTax?.max!, currencyCode)}
    </span>
  )
}
